<template>
  <div>
    <vx-page-title-actions>
      <span v-if="isDragged" class="ml-1">
        <vx-button
          class="mr-1"
          variant="success"
          :loading="loading"
          @click="updatePositions"
        >
          Apply
        </vx-button>
        <vx-button
          variant="danger"
          @click="cancelPositions"
        >
          Discard
        </vx-button>
      </span>
    </vx-page-title-actions>
    <vx-table
      ref="tableRef"
      :resource="resource"
      :columns="columns"
      sortable
      :reset-sortable-cache="resetSortableCache"
      @sortableCallback="onDragged"
    />
  </div>
</template>

<script>
import {
  VxCellBadges,
  VxCellButtons,
  VxTable,
  VxCellDraggable, VxCellMoney
} from '@/components/table'
import VxPageTitleActions from '@/components/layout/VxPageTitleActions'
import { VxButton } from '@/components/buttons'
import { ref } from '@vue/composition-api'
import { boolStatuses, paymentSystems, useResource } from '@/services/resources'
import { useDraggable } from '@/services/draggable'
import { buttons } from '@/components/table/vx-table-cells/vx-cell-button'
import { buildRoute, path } from '@/router'
import { boolStatusFormat, moneyFormat } from '@/services/utils'

export default {
  name: 'PaymentSettings',
  components: {
    VxPageTitleActions,
    VxTable,
    VxCellDraggable,
    VxCellMoney,
    VxCellBadges,
    VxCellButtons,
    VxButton
  },
  setup () {
    const tableRef = ref(null)

    const resource = useResource(paymentSystems.getAll)

    const { can: canEdit } = useResource(paymentSystems.getOne)

    const {
      isDragged,
      resetSortableCache,
      loading,
      onDragged,
      cancelPositions,
      updatePositions
    } = useDraggable(
      tableRef,
      paymentSystems.updatePositions,
      'payment_systems',
      'order'
    )

    const actionButtons = ({ id }) => {
      const editButton = {
        ...buttons.edit,
        can: canEdit,
        to: buildRoute(path.paymentSystemUpdate, { params: { id } })
      }

      return [editButton]
    }

    const columns = [
      {
        key: 'order',
        label: 'position',
        component: VxCellDraggable
      },
      { key: 'title' },
      {
        key: 'is_active',
        component: VxCellBadges,
        tdAttr: {
          colors: {
            [boolStatuses['0']]: 'danger',
            [boolStatuses['1']]: 'success'
          }
        },
        formatter: (isActive) => boolStatusFormat(isActive)
      },
      {
        key: 'is_visible',
        component: VxCellBadges,
        tdAttr: {
          colors: {
            [boolStatuses['0']]: 'danger',
            [boolStatuses['1']]: 'success'
          }
        },
        formatter: (isVisible) => boolStatusFormat(isVisible)
      },
      {
        key: 'type',
        component: VxCellBadges,
        tdAttr: {
          colors: {
            skins: 'secondary',
            card: 'dark',
            any: 'primary'
          }
        }
      },
      {
        key: 'is_email_required',
        component: VxCellBadges,
        tdAttr: {
          colors: {
            [boolStatuses['0']]: 'danger',
            [boolStatuses['1']]: 'success'
          }
        },
        formatter: (isEmailRequired) => boolStatusFormat(isEmailRequired)
      },
      {
        key: 'is_email_visible',
        component: VxCellBadges,
        tdAttr: {
          colors: {
            [boolStatuses['0']]: 'danger',
            [boolStatuses['1']]: 'success'
          }
        },
        formatter: (isEmailVisible) => boolStatusFormat(isEmailVisible)
      },
      {
        key: 'min_amount',
        component: VxCellMoney
      },
      {
        key: 'max_amount',
        component: VxCellMoney
      },
      {
        key: 'suggested_amount',
        component: VxCellMoney
      },
      {
        key: 'suggested_amount_list',
        formatter: (list) => {
          if (!list) return
          return list.map(amount => moneyFormat(amount))
        },
        component: VxCellBadges
      },
      {
        key: 'new_player_max_amount',
        component: VxCellMoney
      },
      {
        key: 'actions',
        component: VxCellButtons,
        tdAttr: (value, key, item) => ({
          buttons: actionButtons(item)
        })
      }
    ]

    return {
      tableRef,
      resource,
      columns,

      onDragged,
      isDragged,
      loading,
      cancelPositions,
      updatePositions,
      resetSortableCache
    }
  }
}
</script>
